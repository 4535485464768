@import url(https://fonts.googleapis.com/css?family=Kumbh+Sans:300,400,700|Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, button {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: proxima-nova, 'Open Sans', Helvetica, Arial, sans-serif !important;
  font-weight: 300 !important;
  font-style: normal;
  color: '#4A4A4A';
  overflow-x: hidden;
}

.App h1 {
  font-size: 64px;
  font-weight: 100;
  margin-bottom: 24px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-field {
  width: 100%;
}

.field-wrapper {
  padding: 16px;
}

.form-label {
  text-transform: uppercase;
  margin-bottom: 16px;

}

.App-button {
  width: 400px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: bold;
  background: #FEC855 !important;
  border-radius: 30px;
  color: black !important;
  border: 0;
  font-size: 24px !important;
}

.react-jinke-music-player-main .music-player-panel .panel-content .img-rotate {
  -webkit-animation: none !important;
          animation: none !important;
}

.react-jinke-music-player-mobile-cover, .react-jinke-music-player-mobile-cover .cover {
  -webkit-animation: none !important;
          animation: none !important;
}

.SecondPanel {
  background: url('https://sunstone-storage-public.s3.amazonaws.com/website-resources/Vector.png');
}

.HomeEntryPanel {
  min-height: calc(100vh - 250px);
  background-size: cover;
  background-position: center;
  background-image: url('/homepage/path-darker.jpg');
  position: relative;
}

.HomeEntryPanel::before {  
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-color: black;
  position: absolute;
  opacity: 0.4;
  z-index: 0;
}

.HomeHeader {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 18px;
  background: transparent;

  transition: 0.5s;
}

.HomeHeaderButton {
  /* Button */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 120%;
  /* or 19px */

  text-align: center;
  letter-spacing: 1px;

  cursor: pointer;

  /* Blue */

  color: #fff;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px;
}

.HomeHeaderItem {
  margin: 0px 0px !important;
}

.HomeEntryPanel-Title {
  position: absolute;
  bottom: 20ch;
  max-width: 800px;
}

.quote {
  font-family: Kumbh Sans;
  font-style: normal;
  font-weight: bold !important;
  font-size: 38px !important;
  line-height: 120% !important;
  /* or 65px */

  margin-bottom: 3vh !important;
  /* margin-left: 80px !important; */

  letter-spacing: 1px;

  /* White */

  color: #FFFFFF !important;
}

.quote-small {
  font-family: Kumbh Sans !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 24px !important;
  line-height: 120% !important;
  /* or 19px */

  letter-spacing: 1px;
  margin-bottom: 10ch !important;
}

@media only screen and (max-height: 800px) {
  .quote {
    font-size: 34px !important;
    line-height: 44px !important;
  }

  .quote-small {
    margin-bottom: 2ch !important;
  }
}

.card-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.c {
  position: absolute;
  max-width: 600px;
  max-height: 600px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  will-change: transform, opacity;
}

.front,
.back {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

@media only screen and (max-width: 740px) {
  .HomeHeaderButton {
    color: #2C6277 !important;
  }
}

@media only screen and (max-width: 600px) {
  .c {
    width: 100%;
    height: 100%;
  }
  .card-container {
    height: 50ch;
  }

  .HomeEntryPanel {
    min-height: calc(100vh - 210px);
  }

  .quote {
    font-size: 20px !important;
    line-height: 26px !important;
    margin-bottom: 16px !important;
  }

  .quote-small {
    font-size: 14px !important;
  }

  .front,
  .back {
    background-size: contain !important;
  }
}

.GetInTouchButton {
  border: 2px solid white;
  box-sizing: border-box;
  border-radius: 31px;
  padding: 15px 20px;
  cursor: pointer;
  position: fixed;
  right: 80px;
  top: 40px;
  z-index: 1000;
  transition: 0.5s;
}

.HomeHeaderItem {
  padding: 15px 20px;
}

@media only screen and (max-width: 1600px) {
  .HomeHeaderItem {
    padding: 15px 24px;
  }
}

.HomeHeaderItem.link {
  border: 2px solid transparent;
}

.HomeHeaderItem.mobile-link {
  border: 2px solid transparent;
}

.HomeHeaderItem.mobile-link-2:hover p {
  color: #2C6277 !important;

}

.HomeHeaderItem.mobile-link:hover {
  background: rgba(44, 98, 119, 0.5);
  border: 2px solid transparent;
  border-radius: 31px;
  color: white !important;
  cursor: pointer;
  transition: background 0.5s, border 0.1s;
}

.HomeHeaderItem.link:hover {
  background: rgba(44, 98, 119, 0.5);
  border: 2px solid transparent;
  border-radius: 31px;
  color: white !important;
  cursor: pointer;
  transition: background 0.5s, border 0.1s;
}

.HomeHeaderItem:hover p {
  color: white !important;
}

.GetInTouchButton:hover {
  background: white;
}

.GetInTouchButton:hover p {
  color: white !important;
}

.HomeHeaderItem.link {
  width: 245px;
}

@media only screen and (max-width: 1800px) {
  .HomeHeaderItem.link {
    width: 220px;
  }
}

@media only screen and (max-width: 1700px) {
  .HomeHeaderItem.link {
    width: 200px;
  }

  
}

@media only screen and (max-width: 1480px) {
  .HomeHeaderItem.link {
    width: 200px;
    padding: 15px 12px;
  }

}

@media only screen and (max-width: 1400px) {
  .HomeHeaderItem.link {
    width: 175px;
  }
}

@media only screen and (max-width: 800px) {
  .quote {
    font-size: 24px !important;
    line-height: 120% !important;
  }

  .quote-small {
    font-size: 14px !important;
    line-height: 120% !important;
  }
}

.GetInTouchButton {
  width: 150px !important;
}

.bm-item:focus {
  outline: none !important;
}
